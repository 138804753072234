export interface IAlert {
    alertType: AlertType,
    message: string,
    title: string
}

export enum AlertType {
    success = "success",
    info = "info",
    warning = "warning",
    danger = "danger"
}