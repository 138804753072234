import { COLORS } from 'constants/contants';
import { Container, Row, Col, Button } from 'react-bootstrap';

const NotFound = () => {
    return (
        <Container className="mt-5">
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <h1 className="display-1">404</h1>
                    <p className="lead">Page Not Found</p>
                    <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                    <Button href="/" style={{ backgroundColor: COLORS.orange, borderColor: COLORS.orange }}>Go Home</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default NotFound;