import React, { createContext, useReducer } from 'react';

export interface ILoadingContext {
    showLoading: boolean,
    displayLoading: () => void;
    hideLoading: () => void;
}

const initialState: ILoadingContext = {
    showLoading: false,
    displayLoading: () => { },
    hideLoading: () => { },
}

function reducer(state: ILoadingContext, action: any): ILoadingContext {
    switch (action.type) {
        case 'SHOW_LOADING':
            return { ...state, showLoading: true };
        case 'HIDE_LOADING':
            return { ...state, showLoading: false };
        default:
            throw new Error("Not a valid option");
    }
}

const LoadingContext = createContext(initialState);

function LoadingProvider(props: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const displayLoading = () => {
        dispatch({ type: 'SHOW_LOADING' });
    };

    const hideLoading = () => {
        dispatch({ type: 'HIDE_LOADING' });
    };

    return (
        <LoadingContext.Provider value={{ ...state, displayLoading, hideLoading }}>
            {props.children}
        </LoadingContext.Provider>
    );
}

export { LoadingContext, LoadingProvider };
