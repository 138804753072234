import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { AlertContext } from 'store/contexts/AlertContext';

function Alert() {
    const { alert, showAlert, hideAlert } = useContext(AlertContext);

    return (
        showAlert &&
        ReactDOM.createPortal(
            <div className="modal show d-block" tabIndex={-1} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className={`modal-header bg-${alert.alertType} text-white`}>
                            <h5 className="modal-title">{alert.title}</h5>
                        </div>
                        <div className="modal-body text-center">
                            {alert.message}
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className={`btn btn-${alert.alertType}`} onClick={hideAlert}>Close</button>
                        </div>
                    </div>
                </div>
            </div>,
            document.body,
        )
    );
}

export default Alert;