import React from 'react';
import './Footer.css';
import { COLORS } from 'constants/contants';


const Footer: React.FC = () => {
    const currentYear: number = new Date().getFullYear();

    return (
        <div className="sticky-footer" style={{ color: COLORS.orange }}><b>CardiLink © {currentYear}</b></div>
    );
};

export default Footer;