import Login from 'containers/Login/Login';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import NotFound from 'containers/NotFound/NotFound';
import Environments from 'containers/Environments/Environments';
import { AlertProvider } from 'store/contexts/AlertContext';
import Alert from 'components/alert/Alert';
import { LoadingProvider } from 'store/contexts/LoadingContext';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';

function App() {
  return (
    <LoadingProvider>
      <AlertProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/environments" element={<Environments />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Alert />
        <LoadingSpinner />
        <Footer />
      </AlertProvider>
    </LoadingProvider>
  );
}

export default App;
