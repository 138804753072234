import React, { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { IAuthenticationEnvironment, environmentType } from 'api/interfaces/IAuthentication';
import { COLORS } from 'constants/contants';
import ImageHeader from 'assets/images/maps.png';
import { Badge, Button, Card, CardImg, Container, Row, } from 'react-bootstrap';

const containerStyle = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const cardStyle = {
    width: '18rem',
    margin: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
};

const buttonStyle = {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    width: '100%',
    borderColor: COLORS.orange
};

const authenticatedStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: COLORS.white,
    borderRadius: '5px',
    marginBottom: '20px'
};

const cardFooterStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0)'
}

const Environments: React.FC = () => {
    const location = useLocation();

    const [environments] = useState<IAuthenticationEnvironment[]>(location.state?.environments as IAuthenticationEnvironment[]);

    if (!location.state?.environments) return <Navigate replace to="/" />

    const handleButtonClick = (url: string, token: string) => {
        let cookie = `authkey=${token}; domain=.cardi-link.cloud; path=/;`;
        document.cookie = cookie;
        window.location.href = url + "Home";
    };

    return (
        <div>
            <style>
                {`
                html, body {
                    height: 100%;
                    margin: 0;
                }

                .custom-badge {
                    background-color: ${COLORS.orange} !important;
                }

                .background-container::before {
                    content: "";
                    background-image: url('${ImageHeader}');
                    opacity: 0.2;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    z-index: -1;
                    background-size: cover;
                    background-repeat: repeat
            `}
            </style>
            <Container fluid style={containerStyle} className="flex-column background-container">
                <div style={authenticatedStyle}>
                    Authenticated as {environments[0].username}
                </div>
                <Row className="justify-content-center align-items-center align-content-center h-100">
                    {environments.map((env, index) => (
                        <Card className='rounded-4' key={index} style={cardStyle}>
                            <Badge pill className='custom-badge' style={{
                                color: 'white',
                                padding: '5px 10px',
                                borderRadius: '10px',
                                position: 'absolute',
                                top: '10px',
                                right: '10px'
                            }}>
                                {environmentType[env.environmentType]}
                            </Badge>
                            <CardImg variant='top' src={env.imageLogoUrl} alt={env.environment} style={{ width: '100%', height: '10rem', objectFit: 'contain' }}></CardImg>
                            <Card.Footer className="border-0" style={cardFooterStyle}>
                                <Button style={buttonStyle} onClick={() => handleButtonClick(env.url, env.token)}>Go to this Portal</Button>
                            </Card.Footer>
                        </Card>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default Environments;
