import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { COLORS } from 'constants/contants';
import { ILogin } from 'api/interfaces/ILogin';
import { IApiResult } from 'api/interfaces/IAPIResults';
import { IAuthenticationEnvironment } from 'api/interfaces/IAuthentication';
import authApi from 'api/AuthApi';

import CardiLinkLogo from 'assets/images/CL.png';
import ImageHeader from 'assets/images/maps.png';
import { AlertType } from 'components/alert/IAlert';
import { AlertContext } from 'store/contexts/AlertContext';
import { LoadingContext } from 'store/contexts/LoadingContext';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const containerStyle = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const cardStyle = {
    width: '20rem',
    margin: '10px',
    backgroundColor: 'rgba(255, 255, 255, 1)'
};

const buttonStyle = {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    width: '100%',
    borderColor: COLORS.orange
};

const cardFooterStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0)'
}

const Login: React.FC = () => {
    const [loginData, setLoginData] = useState<ILogin>({ login: '', password: '' });
    const [environments, setEnvironments] = useState<IAuthenticationEnvironment[] | null>(null);
    const navigate = useNavigate();
    const { displayAlert } = useContext(AlertContext);
    const { displayLoading, hideLoading } = useContext(LoadingContext);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginData({
            ...loginData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (loginData.login === '' || loginData.password === '') {
            displayAlert({ title: "Missing information!", message: "It seems like you have forgotten to enter your username or password. Please make sure to fill in both fields.", alertType: AlertType.danger })
            return;
        }

        displayLoading();

        try {
            const { data } = await authApi.get<IApiResult>('/auth/login', { params: loginData });

            if (!data.success) {
                displayAlert({ title: "Error connecting to CardiLink!", message: "It seems that we cannot connect to our servers right now. Please try again later.", alertType: AlertType.danger });
                console.error(data.error);
                return;
            }

            let environments = data.data as IAuthenticationEnvironment[];

            if (environments.length === 0) {
                displayAlert({ title: "No credentials found!", message: "It seems that we did not find your credentials in our systems. Please try again later.", alertType: AlertType.danger });
                return;
            }

            setEnvironments(environments);
        } catch (error) {
            console.error('Error:', error);
            displayAlert({ title: "Error connecting to CardiLink!", message: "It seems that we cannot connect to our servers right now. Please try again later.", alertType: AlertType.danger })
        } finally {
            hideLoading();
        }
    };

    useEffect(() => {
        if (environments) {
            navigate("/environments", { state: { environments } });
        }
    }, [environments, navigate]);

    return (
        <div>
            <style>
                {`
                .background-container::before {
                    content: "";
                    background-image: url('${ImageHeader}');
                    opacity: 0.5;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    z-index: -1;
                    background-size: cover;
                    background-repeat: repeat
                }
            `}
            </style>
            <Container className='background-container' fluid style={containerStyle}>
                <Row className='min-vh-100'>
                    <Col md='7' className='d-flex flex-column justify-content-center align-items-end'>
                        <Card className='rounded-4' style={cardStyle}>
                            <Card.Body>
                                <form >
                                    <div className="mb-3">
                                        <label htmlFor="login" className="form-label"><b>E-mail</b></label>
                                        <input type="text" name="login" id="login" className="form-control" placeholder="Your e-mail address" aria-label="Your e-mail address" aria-describedby="basic-addon1" value={loginData.login} onChange={handleChange} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label"><b>Password</b></label>
                                        <input type="password" name="password" id="password" className="form-control" placeholder="Your password" value={loginData.password} onChange={handleChange} />
                                    </div>
                                </form>
                            </Card.Body>
                            <Card.Footer className="border-0" style={cardFooterStyle}>
                                <Button style={buttonStyle} onClick={handleSubmit}><b>Login</b></Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='5'>
                        <img style={{ maxWidth: '80%', height: 'auto', marginTop: '50px', opacity: 1 }} src={CardiLinkLogo} alt='CardiLink logo' />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;