import { AlertType, IAlert } from 'components/alert/IAlert';
import React, { createContext, useReducer } from 'react';

export interface IAlertContext {
    alert: IAlert
    showAlert: boolean,
    displayAlert: (alert: IAlert) => void;
    hideAlert: () => void;
}

const initialState: IAlertContext = {
    alert: { message: "", alertType: AlertType.info, title: "" },
    showAlert: false,
    displayAlert: () => { },
    hideAlert: () => { },
};

function reducer(state: IAlertContext, action: any): IAlertContext {
    switch (action.type) {
        case 'SHOW_ALERT':
            return { ...state, alert: action.payload, showAlert: true };
        case 'HIDE_ALERT':
            return { ...state, showAlert: false };
        default:
            throw new Error("Not a valid option");
    }
}

const AlertContext = createContext(initialState);

function AlertProvider(props: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const displayAlert = (alert: IAlert) => {
        dispatch({ type: 'SHOW_ALERT', payload: alert });
    };

    const hideAlert = () => {
        dispatch({ type: 'HIDE_ALERT' });
    };

    return (
        <AlertContext.Provider value={{ ...state, displayAlert, hideAlert }}>
            {props.children}
        </AlertContext.Provider>
    );
}

export { AlertContext, AlertProvider };
