export interface IAuthenticationEnvironment {
    username: string
    url: string
    environment: string
    token: string
    environmentType: environmentType
    imageLogoUrl: string
}

export enum environmentType {
    Production = 0,
    Test = 1,
    Demo = 2
}