import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './LoadingSpinner.css';
import { LoadingContext } from 'store/contexts/LoadingContext';
import { COLORS } from 'constants/contants';

function LoadingSpinner() {
    const { showLoading } = useContext(LoadingContext);

    return showLoading && ReactDOM.createPortal(
        <div className="loading-spinner-overlay">
            <div className="loading-spinner-container">
                <div className="spinner-border" style={{ color: COLORS.orange }} role="status">
                    <span className="sr-only"></span>
                </div>
                <div style={{ marginTop: '10px' }}>Loading...</div>
            </div>
        </div>,
        document.body,
    );
}

export default LoadingSpinner;